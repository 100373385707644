import { configureStore } from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import accessManagementReducer from '../features/accessManagement/accessManagementSlice';
import verificationReducer from '../features/verification/verificationSlice';

export const store = configureStore({
  reducer: {
    accessManagement: accessManagementReducer,
    verification: verificationReducer
  }
});
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();

import { Access } from '../enums';

export const ModalEvent = {
  StartAccessManagementUpsell: 'StartAccessManagementUpsell'
};

export type AccessManagementUpsellEventParams = {
  featureName: string;
  redirectLink: string;
  closeCallback: (access: Access) => string;
};

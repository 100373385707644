import { httpService } from 'core-utilities';
import { getAmpUpsellUrlConfig } from '../../constants/urlConstants';
import AmpResponse from './AmpResponse';

// function to fetch AMP response
export const fetchFeatureCheckResponse = (featureName: string) => {
  const urlConfig = getAmpUpsellUrlConfig(featureName);

  return new Promise(resolve => {
    httpService.get(urlConfig).then(
      ({ data }) => {
        resolve(data as AmpResponse);
      },
      e => {
        throw e;
      }
    );
  });
};

export default fetchFeatureCheckResponse;

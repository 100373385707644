import { ModalEvent } from '../constants/viewConstants';
import { Access } from '../enums';

const isFeatureAccessGranted = (access: Access) => {
  return access === Access.Granted;
};

export const startAccessManagementUpsell = async (
  featureName: string,
  redirectLink: string
): Promise<boolean> => {
  return new Promise(resolve => {
    const event = new CustomEvent(ModalEvent.StartAccessManagementUpsell, {
      detail: {
        featureName,
        redirectLink,
        closeCallback: (access: Access) => {
          resolve(isFeatureAccessGranted(access));
        }
      }
    });
    window.dispatchEvent(event);
  });
};

export default {
  startAccessManagementUpsell
};

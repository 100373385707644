import PropTypes from 'prop-types';
import React from 'react';
import { TranslateFunction } from 'react-utilities';
import { useSelector } from 'react-redux';
import { selectCurrentVerificationMethod } from '../accessManagement/accessManagementSlice';
import { VerificationMethod } from '../../enums';
import IDVerification from './components/IDVerification/IDVerificationContainer';

function VerificationContainer({
  translate,
  onHide
}: {
  translate: TranslateFunction;
  onHide: () => void;
}): React.ReactElement {
  const currentVerificationMethod = useSelector(selectCurrentVerificationMethod);

  let verificationComponent = null;
  switch (currentVerificationMethod) {
    case VerificationMethod.IDV:
      verificationComponent = <IDVerification translate={translate} onHide={onHide} />;
      break;
    default:
      verificationComponent = <div />;
      break;
  }

  return <React.Fragment>{verificationComponent}</React.Fragment>;
}

export default VerificationContainer;

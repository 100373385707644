import React, { useState } from 'react';
import { TranslateFunction } from 'react-utilities';
import { Modal } from 'react-style-guide';
import { useSelector } from 'react-redux';
import {
  fetchFeatureAccess,
  resetAccessManagementStore,
  selectCurrentStage,
  selectFeatureAccess,
  selectShowUpsell,
  setRedirectLink
} from './accessManagementSlice';
import { useAppDispatch } from '../../store';
import { ModalEvent, AccessManagementUpsellEventParams } from '../../constants/viewConstants';
import VerificationContainer from '../verification/VerificationContainer';
import { resetVerificationStore } from '../verification/verificationSlice';
import { Access, UpsellStage } from '../../enums';
import Prologue from './components/Prologue';
import Epilogue from './components/Epilogue';

function AccessManagementContainer({
  translate
}: {
  translate: TranslateFunction;
}): React.ReactElement {
  let displayContainer;
  const dispatch = useAppDispatch();
  const currentStage = useSelector(selectCurrentStage);
  const featureAccess = useSelector(selectFeatureAccess);
  const showUpsellModal = useSelector(selectShowUpsell);
  const [onHidecallback, setOnHidecallbackCallback] = useState<(access: Access) => string>(
    (access: Access) => access
  );

  function onAccessManagementCustomEvent(event: CustomEvent<AccessManagementUpsellEventParams>) {
    const { featureName, redirectLink, closeCallback } = event.detail;
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    dispatch(fetchFeatureAccess(featureName));
    dispatch(setRedirectLink(redirectLink));
    setOnHidecallbackCallback(() => (access: Access): string => closeCallback(access));
  }

  function onHide() {
    dispatch(resetAccessManagementStore());
    dispatch(resetVerificationStore());
    onHidecallback(featureAccess.data.access);
  }

  window.addEventListener(
    ModalEvent.StartAccessManagementUpsell,
    onAccessManagementCustomEvent as EventListener
  );

  switch (currentStage) {
    case UpsellStage.Prologue:
      displayContainer = <Prologue translate={translate} />;
      break;
    case UpsellStage.Verification:
      displayContainer = <VerificationContainer translate={translate} onHide={onHide} />;
      break;
    case UpsellStage.Epilogue:
      displayContainer = <Epilogue translate={translate} />;
      break;
    default:
      displayContainer = <Prologue translate={translate} />;
      break;
  }

  return (
    <React.Fragment>
      <Modal
        show={showUpsellModal}
        onHide={onHide}
        size='sm'
        aria-labelledby='access-management-modal-title'
        className='access-management-upsell-modal'
        scrollable='true'
        centered='true'>
        {displayContainer}
      </Modal>
    </React.Fragment>
  );
}
export default AccessManagementContainer;
